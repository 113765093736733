import { FC, useCallback, useState } from 'react';
import { IService } from '../../models/IService';
import ServiceButton from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceButton';
import { getNewMultiServicesValue } from '../../utils/services';
import { useMediaQuery } from '@mui/material';
import ServiceInfoDialog from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceInfoDialog';
import ServiceInfo from '../../views/scheduling-widget/widget-wizard/forms/service-step/ServiceInfo';

interface IWidgetServiceSelectProps {
    options: IService[];
    value: IService[];
    onChange: (v: IService[]) => void;
    useMultiservices?: boolean;
    disableFn?: (s: IService) => boolean;
    openInfoCb?: () => void;
    closeInfoCb?: () => void;
}

const WidgetServiceSelect: FC<IWidgetServiceSelectProps> = ({
    options,
    value,
    onChange,
    useMultiservices = false,
    disableFn,
    openInfoCb,
    closeInfoCb
}) => {
    const matchDownMd = useMediaQuery('(max-width: 800px)');

    const [selectedService, setSelectedService] = useState<IService | null>(null);

    const isSelected = useCallback((serviceId: number) => value.some(({ id }) => id === serviceId), [value]);

    const handleSelectService = useCallback(
        (arg: IService) => {
            if (isSelected(arg.id)) {
                const newValue = useMultiservices ? [...value].filter((s) => s.id !== arg.id) : [];
                onChange(newValue);
            } else {
                const ids = [...value, arg].map(({ id }) => id);
                const newIds = getNewMultiServicesValue(ids, options);
                const newValue = useMultiservices
                    ? options?.filter(({ id }) => newIds.includes(id)).sort((a, b) => newIds.indexOf(a.id) - newIds.indexOf(b.id))
                    : [arg];
                onChange(newValue);
            }
        },

        [isSelected, onChange, options, useMultiservices, value]
    );

    const handleDialogOpen = useCallback(
        (service: IService) => {
            setSelectedService(service);
            openInfoCb?.();
        },
        [openInfoCb]
    );

    const handleDialogClose = useCallback(() => {
        setSelectedService(null);
        closeInfoCb?.();
    }, [closeInfoCb]);

    const onChoose = useCallback(() => {
        if (selectedService) {
            handleSelectService(selectedService);
            handleDialogClose();
        }
    }, [handleDialogClose, handleSelectService, selectedService]);

    return (
        <>
            {selectedService && matchDownMd ? (
                <ServiceInfo service={selectedService} onClose={handleDialogClose} onChoose={onChoose} />
            ) : (
                <>
                    {options.map((service: IService) => (
                        <ServiceButton
                            key={service.id}
                            service={service}
                            onChoose={() => {
                                handleSelectService(service);
                                handleDialogClose();
                            }}
                            isSelected={isSelected(service.id)}
                            handleClickInfo={() => {
                                handleDialogOpen(service);
                            }}
                            disabled={disableFn ? disableFn(service) : undefined}
                        />
                    ))}
                </>
            )}
            {!!selectedService && !matchDownMd && (
                <ServiceInfoDialog open onChoose={onChoose} onClose={handleDialogClose} service={selectedService} />
            )}
        </>
    );
};

export default WidgetServiceSelect;
