import * as Yup from 'yup';
import servicesSchema from '../form/schemes/services-array-schema';
import { depositRequestSchema } from '../form/deposit-request/DepositRequestForm';
import { AppointmentServiceRowType } from './elements/appointment-services-subform';
import { ServiceLocationType } from '../../models/IService';
import { getSuggestion, parseStringToDate } from '../../hooks/useFreeDateInput';

const appointmentSchema = Yup.object().shape({
    customer: Yup.object().required('Customer is required').typeError('Customer is required'),
    employee: Yup.object().required('Provider is required').typeError('Provider is required'),
    services: Yup.array().of(servicesSchema).min(1, 'At least one service is required').required('Services is required'),
    note: Yup.string().nullable(),
    private_note: Yup.string().nullable(),
    start_at: Yup.string().required('Start date is required').typeError('Start date is invalid'),
    duration: Yup.number().min(10, 'Minimal allowed duration is 10 minutes').max(600, 'Maximal allowed duration is 10 hours (600 minutes)'),
    is_notifications_enabled: Yup.boolean(),
    deposit_request: depositRequestSchema,
    remind_after_interval: Yup.mixed().when('use_reminder', {
        is: true,
        then: Yup.string()
            .trim()
            .required('Remind Interval is required')
            .test({
                name: 'remind_after_interval',
                test: (v) => {
                    if (v) {
                        const parsedObj = parseStringToDate(v);
                        const suggestion = getSuggestion(parsedObj);

                        return !!suggestion;
                    }
                    return true;
                },
                message: 'Unable to parse date'
            }),
        otherwise: Yup.string().nullable().notRequired()
    }),
    address: Yup.mixed().when('services', {
        is: (servicesValue: Array<AppointmentServiceRowType>) =>
            servicesValue.some((service) => service?.service?.location_type === ServiceLocationType.External),
        then: Yup.object()
            .shape({
                address: Yup.string().trim().required().label('External Address')
            })
            .typeError('External Address is required field')
            .required()
            .label('External Address'),
        otherwise: Yup.mixed().nullable().notRequired()
    })
});

export default appointmentSchema;
