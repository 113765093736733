import { FC, useState } from 'react';
import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { ICompany, ICompanySettings } from '../../../models/ICompany';
import WebhooksSettingsTable from '../webhooks/WebhooksSettingsTable';
import ApiKeysList from '../api-keys/ApiKeysList';
import { FormikProps } from 'formik';
import FormAccordion from '../../../ui-component/form/FormAccordion';
import GradeUsForm from '../components/GradeUsForm';
import ReviewshakeForm from '../components/ReviewshakeForm';
import InputRow from '../../../ui-component/form/InputRow';
import DetailsBtn from '../components/DetailsBtn';

const options = [
    { value: 'gradeus', label: 'Grade.us' },
    { value: 'reviewshake', label: 'Reviewshake' }
] as const;

const IntegrationsTab: FC<{ formInstance: FormikProps<ICompanySettings>; company: ICompany }> = ({ formInstance, company }) => {
    const [details, setDetails] = useState<'gradeus' | 'reviewshake' | null>(null);
    const { values, errors, handleChange, handleBlur, setFieldValue } = formInstance;

    return (
        <Stack spacing={1.5}>
            <FormAccordion
                label={
                    <InputRow
                        label="Reputation Management"
                        info="Select service for reputation management and enter necessary credentials. Only one service can be active at a time."
                        error={errors.integrations?.reputation_management}
                    />
                }
            >
                <FormControl fullWidth>
                    <RadioGroup
                        id="integrations.reputation_management"
                        name="integrations.reputation_management"
                        value={values.integrations.reputation_management}
                        onChange={(e) => setFieldValue('integrations.reputation_management', e.target.value)}
                    >
                        {options.map(({ value, label }) => (
                            <Stack
                                key={value}
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ fontWeight: 600, width: '100%', color: 'grey.800' }}
                            >
                                <FormControlLabel
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontWeight: 'inherit',
                                            color: 'inherit'
                                        }
                                    }}
                                    value={value}
                                    label={label}
                                    control={<Radio />}
                                />
                                <DetailsBtn onClick={() => setDetails(value)} />
                            </Stack>
                        ))}
                    </RadioGroup>
                </FormControl>
            </FormAccordion>

            <GradeUsForm
                open={details === 'gradeus'}
                data={values.integrations.gradeus}
                onClose={() => setDetails(null)}
                onSave={(v: Record<string, string>) => setFieldValue('integrations.gradeus', v)}
            />

            <ReviewshakeForm
                open={details === 'reviewshake'}
                data={values.integrations.reviewshake}
                onClose={() => setDetails(null)}
                onSave={(v: Record<string, string>) => setFieldValue('integrations.reviewshake', v)}
            />

            <Divider />
            {company.is_twilio_enabled && (
                <>
                    <InputRow
                        label="Twilio Auth Token"
                        info="Please fill out you Twilio account credentials to have the system send SMS notifications to customers and staff."
                        forceColumn
                    >
                        <TextField
                            id="integrations.twilio.auth_token"
                            name="integrations.twilio.auth_token"
                            placeholder="Auth Token"
                            value={values?.integrations?.twilio?.auth_token}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hiddenLabel
                            fullWidth
                        />
                    </InputRow>

                    <InputRow label="Twilio Account SID" forceColumn>
                        <TextField
                            id="integrations.twilio.account_sid"
                            name="integrations.twilio.account_sid"
                            placeholder="Account SID"
                            value={values?.integrations?.twilio?.account_sid}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                    </InputRow>

                    <Divider />
                </>
            )}
            <WebhooksSettingsTable companyId={company.id} />

            <Divider />

            <ApiKeysList companyId={company.id} />
        </Stack>
    );
};

export default IntegrationsTab;
