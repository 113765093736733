import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DialogTypes {
    Appointment = 'appointment',
    Customer = 'customer',
    BlockTime = 'block_time',
    Invite = 'invite',
    Ledger = 'ledger'
}

interface IEntityDialogsSliceState {
    entityType: DialogTypes | null;
    entityId: number | null;
}

const initialState: IEntityDialogsSliceState = {
    entityType: null,
    entityId: null
};

export const entityDialogsSlice = createSlice({
    name: 'entityDialogs',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<{ type: DialogTypes; id: number | null }>) => {
            state.entityType = action.payload.type;
            state.entityId = action.payload.id;
        },

        closeDialog: (state) => {
            state.entityType = null;
            state.entityId = null;
        }
    }
});

export const { openDialog, closeDialog } = entityDialogsSlice.actions;
