import { FC, ReactNode, useCallback, useState } from 'react';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useEntityDrawerSpacing from './use-entity-drawer-spacing';

type EntityDrawerHeaderProps = {
    title: ReactNode;
    onClose?: () => void;
    onDelete?: () => void;
};

const EntityDrawerHeader: FC<EntityDrawerHeaderProps> = ({ title, onClose, onDelete }) => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

    const handleMenuClose = useCallback(() => {
        setMenuAnchor(null);
    }, []);

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{
                minHeight: '61px',
                borderBottom: '1px solid',
                borderBottomColor: 'grey.200',
                px: spacingX,
                py: 1,
                backgroundColor: '#fff',
                color: 'grey.800'
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    fontSize: isMobile ? '1.25rem' : '1.5rem',
                    fontWeight: isMobile ? 400 : 600,
                    lineHeight: '1.15'
                }}
            >
                {title}
            </Box>
            <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                {onDelete && (
                    <>
                        <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)} color="inherit">
                            <MoreVertIcon />
                        </IconButton>
                        <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
                            <MenuItem
                                color="error"
                                onClick={() => {
                                    onDelete();
                                    handleMenuClose();
                                }}
                            >
                                <ListItemIcon sx={{ color: 'error.main' }}>
                                    <DeleteOutlineIcon color="inherit" />
                                </ListItemIcon>
                                <ListItemText sx={{ color: 'error.main', '& > *': { color: 'inherit !important' } }}>
                                    Delete Appointment
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                )}
                {onClose && (
                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
        </Stack>
    );
};

export default EntityDrawerHeader;
