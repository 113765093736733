import { FC, FocusEvent } from 'react';
import NumberFormat from 'react-number-format';
import FocusTextField from '../../ui-component/FocusTextField';
import InfoTooltip from '../../ui-component/InfoTooltip';

export interface ISettingsFormDelayRowProps {
    label?: string;
    fieldKey: string;
    value: number | null;
    error?: string | null;
    onChange: (field: string, value: unknown) => void;
    onBlur: (e: FocusEvent) => void;
    scale?: 'Hours' | 'Minutes';
    infoText?: string;
    fullWidth?: boolean;
}

const SettingsFormDelayRow: FC<ISettingsFormDelayRowProps> = ({
    label,
    fieldKey,
    value,
    error,
    onChange,
    onBlur,
    infoText,
    scale = 'Hours',
    fullWidth
}) => (
    <NumberFormat
        hiddenLabel={!label}
        label={label}
        customInput={FocusTextField}
        fullWidth={fullWidth}
        id={fieldKey}
        name={fieldKey}
        value={value}
        error={!!error}
        helperText={error}
        decimalScale={0}
        suffix={scale ? ` ${scale}` : undefined}
        onValueChange={(val) => onChange(fieldKey, val?.floatValue ?? '')}
        onBlur={onBlur}
        InputProps={{
            endAdornment: infoText ? <InfoTooltip label={label} text={infoText} /> : undefined
        }}
        allowNegative={false}
    />
);

export default SettingsFormDelayRow;
