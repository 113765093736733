import { FC, useCallback } from 'react';
import { FormikProps } from 'formik';
import { ICompanySettings, MeetingProvidersType } from '../../../models/ICompany';
import { Box, Divider, Grid, MenuItem, Stack, Switch, TextField } from '@mui/material';
import CommissionSelect from '../../../ui-component/service-select/CommissionSelect';
import FormAccordion from '../../../ui-component/form/FormAccordion';
import { EmployeeCommissionType } from '../../../models/IEmployee';
import InputRow from '../../../ui-component/form/InputRow';

const ExtensionsTab: FC<{ formInstance: FormikProps<ICompanySettings> }> = ({ formInstance }) => {
    const { values, setFieldValue, errors, touched, handleBlur, handleChange } = formInstance;

    const getZoomIntegrationsError = useCallback(
        (field: 'account_id' | 'client_id' | 'client_secret'): string | undefined => {
            const isTouched = typeof touched.integrations?.zoom === 'object' ? Boolean(touched.integrations?.zoom?.[field]) : false;
            const errMessage = typeof errors.integrations?.zoom === 'object' ? errors.integrations?.zoom?.[field] : undefined;

            return isTouched ? errMessage : undefined;
        },
        [touched, errors]
    );

    return (
        <Stack spacing={3}>
            <FormAccordion
                label={<InputRow label="Meetings" info="Select the meeting service utilized for virtual service types." forceTooltips />}
            >
                <Box sx={{ maxWidth: '710px' }}>
                    <Grid spacing={3} container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Meetings Provider"
                                fullWidth
                                id="integrations.meetings"
                                name="integrations.meetings"
                                value={values.integrations.meetings}
                                onChange={(e) => {
                                    if (e.target.value === MeetingProvidersType.Zoom || e.target.value === MeetingProvidersType.Whereby) {
                                        setFieldValue('integrations.meetings', e.target.value);
                                    }
                                }}
                                select
                                onBlur={handleBlur}
                                error={!!errors.integrations?.meetings && !!touched.integrations?.meetings}
                                helperText={touched.integrations?.meetings ? errors.integrations?.meetings : undefined}
                            >
                                <MenuItem value={MeetingProvidersType.Whereby}>Whereby</MenuItem>
                                <MenuItem value={MeetingProvidersType.Zoom}>Zoom</MenuItem>
                            </TextField>
                        </Grid>
                        {values.integrations.meetings === MeetingProvidersType.Zoom ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Zoom Account Id"
                                        fullWidth
                                        id="integrations.zoom.account_id"
                                        name="integrations.zoom.account_id"
                                        value={values.integrations.zoom?.account_id ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!getZoomIntegrationsError('account_id')}
                                        helperText={getZoomIntegrationsError('account_id')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Zoom Client Id"
                                        fullWidth
                                        id="integrations.zoom.client_id"
                                        name="integrations.zoom.client_id"
                                        value={values.integrations.zoom?.client_id ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!getZoomIntegrationsError('client_id')}
                                        helperText={getZoomIntegrationsError('client_id')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Zoom Client Secret"
                                        fullWidth
                                        id="integrations.zoom.client_secret"
                                        name="integrations.zoom.client_secret"
                                        value={values.integrations.zoom?.client_secret ?? ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!getZoomIntegrationsError('client_secret')}
                                        helperText={getZoomIntegrationsError('client_secret')}
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </Box>
            </FormAccordion>

            <Divider />

            <InputRow
                forceTooltips
                label="Enable Commissions"
                info="Commission calculations can be enabled for use alongside generating commission reports.  Default fixed rate or percentage values can be defined. Custom commission rates can be defined when viewing individual staff members."
            >
                <Switch
                    value={Boolean(values.enable_commissions)}
                    checked={Boolean(values.enable_commissions)}
                    onChange={(_, checked) => setFieldValue('enable_commissions', checked)}
                />
            </InputRow>

            {values.enable_commissions ? (
                <Box sx={{ width: '100%', maxWidth: '710px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CommissionSelect
                                label="Default Services Commission"
                                hasOverride
                                type={values.default_commission?.services?.type ?? EmployeeCommissionType.Percent}
                                amount={values.default_commission?.services?.amount ?? null}
                                onTypeChange={(v) => setFieldValue('default_commission.services.type', v)}
                                onAmountChange={(v) => setFieldValue('default_commission.services.amount', v)}
                                error={
                                    // @ts-ignore
                                    touched.default_commission?.services?.amount
                                        ? // @ts-ignore
                                          errors.default_commission?.services?.amount
                                        : undefined
                                }
                                variant="filled"
                                size="medium"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CommissionSelect
                                label="Default Products Commission"
                                hasOverride
                                type={values.default_commission?.products?.type ?? EmployeeCommissionType.Percent}
                                amount={values.default_commission?.products?.amount ?? null}
                                onTypeChange={(v) => setFieldValue('default_commission.products.type', v)}
                                onAmountChange={(v) => setFieldValue('default_commission.products.amount', v)}
                                error={
                                    // @ts-ignore
                                    touched.default_commission?.products?.amount
                                        ? // @ts-ignore
                                          errors.default_commission?.products?.amount
                                        : undefined
                                }
                                variant="filled"
                                size="medium"
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}
            <Divider />

            <InputRow
                forceTooltips
                label="Use Multiservices"
                info="Allow multiple services to be grouped into a single appointment for a single customer. "
            >
                <Switch
                    value={Boolean(values?.widget?.use_multiservices)}
                    checked={Boolean(values?.widget?.use_multiservices)}
                    onChange={(_, checked) => setFieldValue('widget.use_multiservices', checked)}
                />
            </InputRow>

            <Divider />

            <InputRow
                forceTooltips
                label="Product Management"
                info="Enabling product management allows for inventory management as well as adding the sale of products to an appointment for point of sale functionality."
            >
                <Switch
                    value={Boolean(values?.appointments?.use_products)}
                    checked={Boolean(values?.appointments?.use_products)}
                    onChange={(_, checked) => setFieldValue('appointments.use_products', checked)}
                />
            </InputRow>
        </Stack>
    );
};

export default ExtensionsTab;
