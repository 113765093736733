import accountService from '../../services/AccountService';
import { useMemo } from 'react';
import config, { Envs } from '../../config';

const useProviderUrl = () => {
    const { data } = accountService.useGetAccountCardDataQuery(null);

    const webSiteSlugStartAdornment = useMemo(() => {
        switch (config.env) {
            case Envs.Develop:
            case Envs.Staging:
            case Envs.Production: {
                return 'https://';
            }
            default: {
                return `${window.location.origin}${config.apiUrl}/user-site/`;
            }
        }
    }, []);

    const webSiteSlugEndAdornment = useMemo(() => {
        switch (config.env) {
            case Envs.Develop:
            case Envs.Staging: {
                return `.${config.env}.chilled.pro`;
            }
            case Envs.Production: {
                return '.chilled.pro';
            }
            default: {
                return '';
            }
        }
    }, []);

    const providerUrl = useMemo(() => {
        if (data) {
            return `${webSiteSlugStartAdornment}${data.slug}${webSiteSlugEndAdornment}`;
        }

        return null;
    }, [data, webSiteSlugStartAdornment, webSiteSlugEndAdornment]);

    return {
        providerUrl,
        webSiteSlugStartAdornment,
        webSiteSlugEndAdornment
    };
};

export default useProviderUrl;
