import { FC, Fragment, ReactNode, useCallback } from 'react';
import appointmentAPI from '../../../../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { alpha, Box, CircularProgress, Stack, useTheme } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment/moment';
import { AppointmentHistoryEvent, IAppointmentHistory } from '../../../../../../models/IAppointment';
import useEntityDrawerSpacing from '../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import SectionHeading from '../../../../../SectionHeading';

type ActivityEventProps = {
    text: string;
    icon?: ReactNode;
    color?: string;
    createdAt?: string;
    createdBy?: string;
    isMobile?: boolean;
};

const ActivityEvent: FC<ActivityEventProps> = ({ text, icon, createdBy, createdAt, color, isMobile }) => (
    <Stack direction="row" spacing={1} alignItems="center">
        <Box
            sx={{
                width: isMobile ? '36px' : '48px',
                height: isMobile ? '36px' : '48px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color,
                backgroundColor: color ? alpha(color, 0.15) : undefined,

                '& .MuiSvgIcon-root': isMobile
                    ? {
                          fontSize: '1rem'
                      }
                    : undefined
            }}
        >
            {icon ?? <SettingsSuggestIcon />}
        </Box>
        <Box>
            <Box
                sx={{
                    color: 'grey.800',
                    fontWeight: 600,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    textTransform: 'capitalize'
                }}
            >
                {text}
            </Box>
            <Box sx={{ fontSize: '0.875rem', lineHeight: '1.5', opacity: 0.6, '& strong': { fontWeight: 600 } }}>
                {createdAt} <strong>{createdBy}</strong>
            </Box>
        </Box>
    </Stack>
);

const Activity = () => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();
    const theme = useTheme();
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { data, isLoading } = appointmentAPI.useGetAppointmentHistoryQuery(selectedEvent?.id ?? skipToken);

    const eventContent = {
        end_at: { icon: <AccessTimeFilledIcon />, text: 'End time changed', color: '#05843B' },
        start_at: { icon: <AccessTimeFilledIcon />, text: 'Start time changed', color: '#05843B' },
        service_id: { icon: <SettingsSuggestIcon />, text: 'Service changed', color: theme.palette.grey[800] },
        price: { icon: <LocalAtmIcon />, text: 'Price changed', color: '#F86F60' },
        employee_id: { icon: <AccountCircleIcon />, text: 'Provider changed', color: '#3483FA' },
        customer_id: { icon: <AccountCircleIcon />, text: 'Customer changed', color: '#3483FA' },

        note: { icon: <DescriptionIcon />, text: 'Note changed', color: '#708B9E' },
        private_note: {
            icon: <DescriptionIcon />,
            text: 'Private note changed',
            color: '#708B9E'
        },
        status: { icon: <SettingsIcon />, text: 'Status changed', color: '#0A3A5B' },
        payments: { icon: <LocalAtmIcon />, text: 'Payment was changed', color: '#F86F60' },
        is_notifications_enabled: { icon: <NotificationsIcon />, text: 'Notification status changed', color: '#9B59B6' }
    };

    const convertMessageData = useCallback(
        (item: IAppointmentHistory) => {
            const color = '#E67E22';
            const icon = item.event_source === 'widget' ? <OpenInNewIcon /> : <EventIcon />;
            let text: string | null = null;
            switch (item.event) {
                case AppointmentHistoryEvent.Created: {
                    text = 'Appointment created';
                    break;
                }

                case AppointmentHistoryEvent.ServiceCreated: {
                    text = 'Appointment service created';
                    break;
                }

                case AppointmentHistoryEvent.ServiceUpdated: {
                    text = 'Appointment service changed';
                    break;
                }

                case AppointmentHistoryEvent.ServiceDeleted: {
                    text = 'Appointment service deleted';
                    break;
                }

                case AppointmentHistoryEvent.ProductCreated: {
                    text = 'Appointment product created';
                    break;
                }

                case AppointmentHistoryEvent.ProductUpdated: {
                    text = 'Appointment product changed';
                    break;
                }

                case AppointmentHistoryEvent.ProductDeleted: {
                    text = 'Appointment product deleted';
                    break;
                }
            }

            return text ? (
                <ActivityEvent
                    icon={icon}
                    text={text}
                    color={color}
                    createdAt={moment(item.created_at).format('MMM D,YYYY | hh:mm a (z)')}
                    createdBy={item.event_source === 'widget' ? 'via Widget' : `by ${item?.user?.title}`}
                    isMobile={isMobile}
                />
            ) : null;
        },
        [isMobile]
    );

    return (
        <Box px={spacingX}>
            <SectionHeading mb={3}>Activity</SectionHeading>
            <Stack spacing={2} pl={isMobile ? 0 : 2}>
                {isLoading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                        <CircularProgress size={20} />
                        Loading...
                    </Box>
                ) : (
                    data?.map((item, index) => (
                        <Fragment key={`event-${index}`}>
                            {convertMessageData(item)
                                ? convertMessageData(item)
                                : Object.keys(item.modified_values).map((key) => {
                                      const content = eventContent[key as keyof typeof eventContent];
                                      return (
                                          <ActivityEvent
                                              key={key}
                                              color={content?.color ?? theme.palette.grey[800]}
                                              icon={content?.icon}
                                              text={content?.text ?? `${key.replaceAll('_', ' ')} changed`}
                                              createdAt={moment(item.created_at).format('MMM D, YYYY hh:mm a (z)')}
                                              createdBy={item.event_source === 'widget' ? 'via Widget' : `by ${item?.user?.title}`}
                                              isMobile={isMobile}
                                          />
                                      );
                                  })}
                        </Fragment>
                    ))
                )}
            </Stack>
        </Box>
    );
};
export default Activity;
