import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import {
    AppointmentInviteType,
    AppointmentStatuses,
    IAppointment,
    IAppointmentBalanceDetails,
    WidgetAppointmentPayload
} from '../models/IAppointment';
import {
    CreateBookingParams,
    GetRescheduleTimeSlotsParams,
    GetTimeSlotsParams,
    IConvertInvitePayload,
    IWidgetCouponResponse,
    TimeSlot
} from '../views/scheduling-widget/widget-wizard/types';
import { IStripePaymentIntent, IStripePaymentIntentInput, IWidgetCompany } from '../models/ICompany';
import { IEmployeeWidgetResponse } from '../models/IEmployee';

const appointmentWidgetAPI = createApi({
    reducerPath: 'appointmentWidgetAPI',
    tagTypes: ['AppointmentWidget', 'Widget Slots'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getWidgetCompany: build.query<IWidgetCompany, string>({
            query: (slug) => ({
                url: `/public/company/${slug}`,
                method: 'GET'
            }),
            providesTags: (result) => ['AppointmentWidget']
        }),
        getWidgetCompanyWithQueryString: build.query<IWidgetCompany, { slug: string; query: string }>({
            query: ({ slug, query }) => ({
                url: `/public/company/${slug}/?query=${query}`,
                method: 'GET'
            }),
            providesTags: (result) => ['AppointmentWidget']
        }),
        getCustomerAppointment: build.query<
            IAppointment,
            { company_slug: string; appointment_uuid: string; widget_source?: string | undefined }
        >({
            query: ({ company_slug, appointment_uuid, widget_source }) => ({
                url: `/public/company/${company_slug}/appointment/${appointment_uuid}`,
                params: { widget_source },
                method: 'GET'
            }),
            providesTags: (result) => ['AppointmentWidget']
        }),
        setCustomersAppointmentStatus: build.mutation<
            IAppointment,
            { company_slug: string; appointment_uuid: string; status: AppointmentStatuses }
        >({
            query: ({ company_slug, appointment_uuid, status }) => ({
                url: `/public/company/${company_slug}/appointment/${appointment_uuid}/status`,
                method: 'PUT',
                data: {
                    status
                }
            }),
            invalidatesTags: ['AppointmentWidget']
        }),
        updateWidgetAppointment: build.mutation<
            IAppointment,
            { company_slug: string; appointment_uuid: string; data: WidgetAppointmentPayload }
        >({
            query: ({ company_slug, appointment_uuid, data }) => ({
                url: `/public/company/${company_slug}/appointment/${appointment_uuid}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['AppointmentWidget']
        }),
        getTimeSlots: build.query<TimeSlot[], GetTimeSlotsParams>({
            query: ({ slug, date, services, location, employee, slots_request_source }) => ({
                url: `/public/company/${slug}/appointment/slots`,
                method: 'GET',
                params: {
                    date,
                    services,
                    location,
                    employee,
                    slots_request_source
                }
            }),
            providesTags: (result) => ['Widget Slots']
        }),
        getRescheduleTimeSlots: build.query<TimeSlot[], GetRescheduleTimeSlotsParams>({
            query: ({ slug, date, appointment_uuid, any_employee }) => ({
                url: `/public/company/${slug}/appointment/${appointment_uuid}/slots`,
                method: 'GET',
                params: {
                    date,
                    any_employee: any_employee ? 'true' : undefined
                }
            }),
            providesTags: (result) => ['Widget Slots']
        }),

        createWidgetAppointment: build.mutation<IAppointment, CreateBookingParams>({
            query: ({ slug, data }) => ({
                url: `/public/company/${slug}/appointment`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['AppointmentWidget']
        }),
        getStripePaymentIntent: build.query<IStripePaymentIntent, IStripePaymentIntentInput>({
            query: ({ slug, ...rest }) => ({
                url: `/public/company/${slug}/appointment/stripe-payment-intent`,
                method: 'POST',
                data: rest
            })
        }),
        getCsrfCookies: build.query({
            query: () => ({ url: `/csrf-cookie`, method: 'GET' })
        }),

        payRequestedDeposit: build.mutation<
            Record<string, string>,
            {
                company_slug: string;
                appointment_slug: string;
                data: unknown;
            }
        >({
            query: ({ company_slug, appointment_slug, data }) => ({
                url: `/public/company/${company_slug}/appointment/${appointment_slug}/deposit-request`,
                method: 'POST',
                data: { payment_details: data }
            })
        }),

        paySelfCheckout: build.mutation<
            Record<string, string>,
            {
                company_slug: string;
                appointment_uuid: string;
                data: Record<string, unknown>;
                coupon_code?: string;
            }
        >({
            query: ({ company_slug, appointment_uuid, data, coupon_code }) => ({
                url: `/public/company/${company_slug}/appointment/${appointment_uuid}/self-checkout`,
                method: 'POST',
                data: { payment_details: data, coupon_code }
            }),
            invalidatesTags: ['AppointmentWidget']
        }),

        getInviteData: build.query<AppointmentInviteType, { company_slug: string; appointment_invite_uuid: string }>({
            query: ({ company_slug, appointment_invite_uuid }) => ({
                url: `/public/company/${company_slug}/appointment-invite/${appointment_invite_uuid}`,
                method: 'GET'
            })
        }),

        getInviteSlots: build.query<
            TimeSlot[],
            {
                company_slug: string;
                appointment_invite_uuid: string;
                date: string;
            }
        >({
            query: ({ company_slug, appointment_invite_uuid, date }) => ({
                url: `/public/company/${company_slug}/appointment-invite/${appointment_invite_uuid}/slots?date=${date}`,
                method: 'GET'
            })
        }),

        convertInviteToAppointment: build.mutation<
            unknown,
            {
                company_slug: string;
                appointment_invite_uuid: string;
                payload: IConvertInvitePayload;
            }
        >({
            query: ({ company_slug, appointment_invite_uuid, payload }) => ({
                url: `/public/company/${company_slug}/appointment-invite/${appointment_invite_uuid}`,
                method: 'POST',
                data: payload
            })
        }),

        validateSelfCheckoutCoupon: build.mutation<
            IAppointmentBalanceDetails,
            { company_slug: string; appointment_uuid: string; coupon_code: string }
        >({
            query: ({ company_slug, appointment_uuid, coupon_code }) => ({
                url: `public/company/${company_slug}/appointment/${appointment_uuid}/validate-coupon-code`,
                method: 'POST',
                data: { coupon_code }
            })
        }),

        validateBookingCoupon: build.mutation<
            IWidgetCouponResponse,
            {
                company_slug: string;
                service_ids: number[];
                location_id: number;
                coupon_code: string;
            }
        >({
            query: ({ company_slug, service_ids, location_id, coupon_code }) => ({
                url: `public/company/${company_slug}/appointment/validate-coupon-code`,
                method: 'POST',
                data: { service_ids, location_id, coupon_code }
            })
        }),

        getProviderWidgetData: build.query<IEmployeeWidgetResponse, { providerName: string; providerId: number }>({
            query: ({ providerName, providerId }) => ({
                url: `public/employee/${providerName}/${providerId}`,
                method: 'GET'
            })
        }),

        joinWaitlist: build.mutation<
            unknown,
            {
                company_slug: string;
                appointment_uuid: string;
                any_employee: boolean;
            }
        >({
            query: ({ company_slug, appointment_uuid, any_employee }) => ({
                url: `public/company/${company_slug}/appointment/${appointment_uuid}/join-to-waitlist`,
                method: 'POST',
                data: { any_employee }
            })
        })
    })
});

export default appointmentWidgetAPI;
