import { FC, useCallback, useMemo } from 'react';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { Box, Button, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoTooltip from '../../../ui-component/InfoTooltip';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';

type AuthorizeNetFormData = {
    api_login_id?: string;
    transaction_key?: string;
};

type AuthorizeNetFormProps = {
    open: boolean;
    onClose: () => void;
    data: AuthorizeNetFormData | null;
    onSave: (formData: AuthorizeNetFormData) => void;
};

const AuthorizeNetForm: FC<AuthorizeNetFormProps> = ({ open, onSave, data, onClose }) => {
    const containerXSpacing = useCommonSettingsDrawerPx();
    const initialValues = useMemo(
        () => ({
            api_login_id: data?.api_login_id ?? '',
            transaction_key: data?.transaction_key ?? ''
        }),
        [data]
    );
    const { values, handleSubmit, handleChange, handleBlur, errors, touched, resetForm } = useFormik<AuthorizeNetFormData>({
        enableReinitialize: true,
        validateOnChange: true,
        onSubmit: (formData) => {
            onSave(formData);
            onClose();
        },
        initialValues,
        validationSchema: Yup.object().shape(
            {
                api_login_id: Yup.string()
                    .trim()
                    .when('transaction_key', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .nullable()
                            .trim()
                            .required('The Authorize.net API Login ID field is required when Authorize.net Transaction Key is present'),
                        otherwise: Yup.string().trim().nullable().notRequired()
                    }),
                transaction_key: Yup.string()
                    .trim()
                    .when('api_login_id', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .nullable()
                            .trim()
                            .required('The Authorize.net Transaction Key field is required when Authorize.net API Login ID is present.'),
                        otherwise: Yup.string().trim().nullable().notRequired()
                    })
            },
            [['transaction_key', 'api_login_id']]
        )
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [onClose, resetForm]);

    return (
        <CommonDrawer open={open} title="Authorize.net Details " onClose={handleClose}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
            >
                <Stack spacing={3}>
                    <Link href="https://support.authorize.net/knowledgebase/Knowledgearticle/?code=000001271" target="_blank">
                        How to find your Authorize.net Login and Transaction Key{' '}
                        <OpenInNewIcon sx={{ fontSize: '1.15rem', transform: 'translateY(3px)' }} />
                    </Link>
                    <TextField
                        label="API Login ID"
                        id="api_login_id"
                        name="api_login_id"
                        value={values.api_login_id ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.api_login_id && !!errors.api_login_id}
                        helperText={touched.api_login_id ? errors.api_login_id : undefined}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InfoTooltip
                                    label="API Login ID"
                                    text="Fill in to accept CC payments using Authorize.net account. Credentials should be valid and live in to have CC payment choice visible in widget."
                                />
                            )
                        }}
                    />
                    <TextField
                        label="API Transaction Key"
                        id="transaction_key"
                        name="transaction_key"
                        value={values.transaction_key ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.transaction_key && !!errors.transaction_key}
                        helperText={touched.transaction_key ? errors.transaction_key : undefined}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon sx={{ transform: 'rotate(90deg)' }} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button color="primary" className="forcedBg" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default AuthorizeNetForm;
