import React, { FC, useMemo } from 'react';
import { Grid, LinearProgress, Stack, Theme, useMediaQuery } from '@material-ui/core';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import CustomPhoneInput from '../form/CustomPhoneInput';
import AppointmentAddressBox from '../AppointmentAddressBox';
import WidgetStepTitle from './WidgetStepTitle';
import CouponApplyForm from './coupon-apply-form';
import ToggledTooltipWithTitle from '../tooltips/ToggledTooltipWithTitle';
import AttachmentsUpload from '../file-uploader-preview/AttachmentsUpload';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import { IWidgetUser } from '../../views/scheduling-widget/widget-wizard/types';
import * as Yup from 'yup';
import { isValidPhone } from '../../utils/phone-helpers';
import { styled } from '@material-ui/core/styles';
import { IAttachmentFunctionsReturn } from '../../hooks/useAttachmentFunctions';

const userSchema = (isExternal?: boolean) =>
    Yup.object().shape(
        {
            firstname: Yup.string().max(255).required('First Name is required'),
            lastname: Yup.string().max(255).required('Last Name is required'),
            email: Yup.string()
                .nullable()
                .max(255, 'Email must be at most 255 characters')
                .email('Must be a valid email')
                .when('phone', {
                    is: (phone: string) => !phone || phone.length === 0,
                    then: Yup.string().required('Email or Phone is required')
                }),
            phone: Yup.string().when('email', {
                is: (email: string) => !email || email.length === 0,
                then: Yup.string()
                    .required('Phone or Email is required')
                    .test({ name: 'phone', test: (v) => isValidPhone(v ?? ''), message: 'Phone number is not valid' }),
                otherwise: Yup.string().nullable()
            }),
            note: Yup.string().nullable(),
            address: !isExternal
                ? Yup.mixed().nullable().notRequired()
                : Yup.object()
                      .shape({
                          address: Yup.string().trim().required().label('Address')
                      })
                      .required()
                      .typeError('Address is required')
                      .label('Address')
        },
        [['email', 'phone']]
    );

const StyledAttachmentsUpload = styled('div')(({ theme }) => ({
    '& div ': {
        borderColor: theme.palette.widget.green
    },
    '& .MuiButton-root': {
        color: theme.palette.widget.green
    }
}));

const DEFAULTS: IWidgetUser = {
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    note: '',
    address: null
};

interface IWidgetUserFormProps extends IAttachmentFunctionsReturn {
    defaults: IWidgetUser | null;
    onSubmitForm: (data: IWidgetUser) => void;
    formId: string;
    isExternal?: boolean;
    isAttachmentsEnabled?: boolean;
    isCouponsAllowed?: boolean;

    coupon?: { amount: number; type: string; code: string };
    onApplyCoupon: (code: string) => void;
    couponError?: string;
    onDeleteCoupon: () => void;
    onClearCouponError: () => void;
    isCouponValidating?: boolean;
}

const WidgetUserForm: FC<IWidgetUserFormProps> = ({
    defaults,
    onSubmitForm,
    isExternal,
    isAttachmentsEnabled,
    formId,
    isCouponsAllowed,

    coupon,
    onApplyCoupon,
    couponError,
    onDeleteCoupon,
    onClearCouponError,
    isCouponValidating,

    attachments,
    setAttachments,
    attachmentError,
    setAttachmentError,
    attachmentsIdsToDelete,
    setAttachmentsIdsToDelete
}) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldTouched,
        setFieldValue
    } = useExtendedFormik<IWidgetUser>({
        validateOnChange: true,
        initialValues: defaults ?? DEFAULTS,
        validationSchema: userSchema(isExternal),
        onSubmit: onSubmitForm
    });

    const addressError = useMemo(() => {
        if (errors.address && typeof errors.address === 'object') {
            // Because Formik can't recognize correct error type
            // @ts-ignore
            return errors.address?.address;
        }

        return errors.address;
    }, [errors]);

    return (
        <form noValidate onSubmit={handleSubmit} id={formId} autoComplete="off">
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth error={Boolean(touched.firstname && errors.firstname)}>
                                <TextField
                                    fullWidth
                                    id="firstname"
                                    name="firstname"
                                    label="First Name"
                                    value={values.firstname}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setFieldTouched('firstname', false);
                                    }}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                    helperText={touched.firstname && errors.firstname}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth error={Boolean(touched.lastname && errors.lastname)}>
                                <TextField
                                    fullWidth
                                    id="lastname"
                                    name="lastname"
                                    label="Last Name"
                                    value={values.lastname}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setFieldTouched('lastname', false);
                                    }}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                    helperText={touched.lastname && errors.lastname}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        setFieldTouched('email', false);
                                    }}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                                <CustomPhoneInput
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={(v) => {
                                        setFieldTouched('phone', false);
                                        setFieldValue('phone', v);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                            </FormControl>
                        </Grid>

                        {isExternal ? (
                            <Grid item xs={12}>
                                <AppointmentAddressBox
                                    fullWidth
                                    label="Address"
                                    id="address"
                                    name="address"
                                    value={values.address}
                                    onChange={(address) => setFieldValue('address', address)}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.address && errors.address)}
                                    helperText={touched.address ? addressError : undefined}
                                />
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={Boolean(touched.note && errors.note)}>
                                        <TextField
                                            fullWidth
                                            id="note"
                                            name="note"
                                            rows={2}
                                            multiline
                                            label="Note"
                                            value={values.note}
                                            onBlur={handleBlur}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleChange(e);
                                                setFieldTouched('note', false);
                                            }}
                                            error={Boolean(touched.note && errors.note)}
                                            helperText={touched.note && errors.note}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isCouponsAllowed ? (
                            <Grid item xs={12}>
                                <WidgetStepTitle title="Coupon" />
                                <Box mt={2}>
                                    <CouponApplyForm
                                        onApply={onApplyCoupon}
                                        error={couponError}
                                        coupon={coupon}
                                        onDelete={onDeleteCoupon}
                                        clearError={onClearCouponError}
                                        isBooking
                                        isBusy={isCouponValidating}
                                    />
                                </Box>
                            </Grid>
                        ) : null}
                        {isAttachmentsEnabled && (
                            <Grid item xs={12}>
                                <ToggledTooltipWithTitle
                                    title="Attachments:"
                                    tooltipText={
                                        <Stack>
                                            <Typography>Max image size: 20mb</Typography>
                                            <Typography>Limit of images to upload : 5</Typography>
                                            <Typography>Accepted formats: jpg, jpeg, png, bmp, gif</Typography>
                                        </Stack>
                                    }
                                />
                                <StyledAttachmentsUpload>
                                    <AttachmentsUpload
                                        disableFileEdit
                                        attachments={attachments}
                                        setAttachments={setAttachments}
                                        error={attachmentError ?? ''}
                                        setError={setAttachmentError}
                                        attachmentsIdsToDelete={attachmentsIdsToDelete}
                                        setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
                                    />
                                </StyledAttachmentsUpload>
                                {attachmentError && (
                                    <div>
                                        <LinearProgress color="error" variant="determinate" value={100} />
                                        <Typography color="error">{attachmentError}</Typography>
                                    </div>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default WidgetUserForm;
