import menuItems, { NavItemWithChildren } from './index';
import { useCallback, useContext, useMemo } from 'react';
import { UserSubject } from '../models/IUser';
import { AbilityContext } from '../utils/roles/Can';
import useAuth from '../hooks/useAuth';
import useCanSeeSiteBuilder from '../views/site-builder/hooks/use-can-see-sitebuilder';
import { UserRole } from '../models/IEmployee';

const useAvailableMenuItems = () => {
    const { user } = useAuth();
    const ability = useContext(AbilityContext);

    const siteBuilderEnabled = useCanSeeSiteBuilder();
    const currentRole = user?.employee.role.name;
    const isProviderOrFrontDesk = useMemo(() => [UserRole.Provider, UserRole.FrontDesk].some((role) => role === currentRole), [
        currentRole
    ]);
    const productsEnabled = !!user?.currentCompany.settings?.appointments.use_products;

    const canSeeMenuItem = useCallback(
        (role_subject?: string, action = 'list') => (role_subject ? ability.can(action, role_subject as UserSubject) : true),
        [ability]
    );

    const filteredItems = useMemo(
        () =>
            menuItems.items.reduce<NavItemWithChildren[]>((prev, current) => {
                // Hide settings item for provider and Front desk user
                if (isProviderOrFrontDesk && current.id === 'settings-index') {
                    return prev;
                }

                if (current.children) {
                    const filteredChildren = current.children.filter((child) => {
                        if (child.id === 'products') {
                            return canSeeMenuItem(child.role_subject) && productsEnabled;
                        }

                        if (child.id === 'site-builder') {
                            return canSeeMenuItem(child.role_subject) && siteBuilderEnabled;
                        }

                        if (child.id === 'review-management') {
                            return canSeeMenuItem(child.role_subject, 'update');
                        }

                        return canSeeMenuItem(child.role_subject);
                    });
                    return filteredChildren.length ? [...prev, { ...current, children: filteredChildren }] : prev;
                }

                if (current.type === 'item' && !current.children) {
                    return canSeeMenuItem(current.role_subject) || !current.role_subject ? [...prev, current] : prev;
                }

                return prev;
            }, []),
        [canSeeMenuItem, isProviderOrFrontDesk, productsEnabled, siteBuilderEnabled]
    );

    const availableSettings = useMemo(() => filteredItems.find((item) => item.id === 'settings-index')?.children ?? [], [filteredItems]);

    return { filteredItems, availableSettings };
};

export default useAvailableMenuItems;
