import { Box, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import InfoTooltip from '../../InfoTooltip';
import { FC } from 'react';

type LabeledSwitchProps = {
    value?: boolean | null;
    onChange: (e: unknown, v: boolean) => void;
    label: string;
    hint?: string;
    error?: string | null;
    fullWidth?: boolean;
};

const LabeledSwitch: FC<LabeledSwitchProps> = ({ value, onChange, label, hint, fullWidth, error }) => (
    <FormControlLabel
        sx={{
            '&.MuiFormControlLabel-root': { ml: 0, mr: 0, gap: '8px' },
            width: fullWidth ? '100%' : undefined,
            justifyContent: fullWidth ? 'space-between' : undefined
        }}
        control={<Switch value={!!value} checked={!!value} onChange={onChange} />}
        labelPlacement="start"
        label={
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Box component="span" sx={{ fontWeight: 600, color: 'grey.800' }}>
                    {label}
                    {error ? <FormHelperText error>{error}</FormHelperText> : null}
                </Box>
                {hint ? <InfoTooltip label={label} text={hint} /> : null}
            </Box>
        }
    />
);

export default LabeledSwitch;
