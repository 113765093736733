import { FC, useState } from 'react';
import { FormikProps } from 'formik';
import { ICompanySettings } from '../../../models/ICompany';
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Radio,
    InputAdornment
} from '@mui/material';
import TaxTreatmentSelect from '../../../ui-component/form/TaxTreatmentSelect';
import InfoTooltip from '../../../ui-component/InfoTooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import LabeledSwitch from '../../../ui-component/form/labeled-switch/LabeledSwitch';
import AuthorizeNetForm from '../components/AuthorizeNetForm';
import StripeForm from '../components/StripeForm';
import InputRow from '../../../ui-component/form/InputRow';
import DetailsBtn from '../components/DetailsBtn';

const options = [
    {
        value: 'authorize_net',
        label: 'Authorize.net'
    },
    {
        value: 'stripe',
        label: 'Stripe'
    }
] as const;

const PaymentsTab: FC<{ formInstance: FormikProps<ICompanySettings> }> = ({ formInstance }) => {
    const [detailsType, setDetailsType] = useState<'authorize_net' | 'stripe' | null>(null);

    const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formInstance;

    return (
        <>
            <Stack spacing={1.5}>
                <InputRow
                    label="Credit Card Processor"
                    info="Payment processor selection for credit card payments.  Credentials should be valid and in live mode in order to have the payment option visible.  These processor details will be presented for deposit payments, deposit requests and customer self-checkout."
                    error={errors.integrations?.cc_processor}
                    forceTooltips
                >
                    <Switch
                        checked={!!values.integrations.cc_processor}
                        onChange={(_, v) => setFieldValue('integrations.cc_processor', v ? 'authorize_net' : null)}
                    />
                </InputRow>
                {values.integrations.cc_processor ? (
                    <>
                        <FormControl>
                            <RadioGroup
                                value={values.integrations.cc_processor}
                                id="integrations.cc_processor"
                                name="integrations.cc_processor"
                                onChange={(e) => setFieldValue('integrations.cc_processor', e.target.value)}
                            >
                                {options.map((option) => (
                                    <Stack
                                        key={option.value}
                                        spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ fontWeight: 600, color: 'grey.800' }}
                                    >
                                        <FormControlLabel
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: 'inherit',
                                                    color: 'inherit'
                                                }
                                            }}
                                            value={option.value}
                                            label={option.label}
                                            control={<Radio />}
                                        />
                                        <DetailsBtn onClick={() => setDetailsType(option.value)} />
                                    </Stack>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </>
                ) : null}

                <AuthorizeNetForm
                    open={detailsType === 'authorize_net'}
                    onClose={() => setDetailsType(null)}
                    data={values.integrations.authorize_net}
                    onSave={(v) => setFieldValue('integrations.authorize_net', v)}
                />

                <StripeForm
                    open={detailsType === 'stripe'}
                    onClose={() => setDetailsType(null)}
                    data={values.integrations.stripe}
                    onSave={(v) => setFieldValue('integrations.stripe', v)}
                />

                <Divider />

                <InputRow
                    forceTooltips
                    label="PayPal Options"
                    info="For PayPal payments on the booking widget, ensure live, valid credentials for display"
                >
                    <Switch
                        checked={!!values.integrations?.paypal}
                        onChange={(_, v) =>
                            setFieldValue(
                                'integrations.paypal',
                                v
                                    ? {
                                          client_id: '',
                                          client_secret: ''
                                      }
                                    : null
                            )
                        }
                    />
                </InputRow>

                {values.integrations?.paypal ? (
                    <Box sx={{ width: '100%', maxWidth: '710px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="PayPal Client ID"
                                    id="integrations.paypal.client_id"
                                    name="integrations.paypal.client_id"
                                    value={values?.integrations?.paypal?.client_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InfoTooltip
                                                label="PayPal Client ID"
                                                text="Fill in to accept paypal payments through booking widget. Credentials should be valid and live in to have paypal payment choice visible in widget."
                                            />
                                        )
                                    }}
                                    error={!!errors.integrations?.paypal?.client_id && !!touched.integrations?.paypal?.client_id}
                                    helperText={
                                        touched.integrations?.paypal?.client_id ? errors.integrations?.paypal?.client_id : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="PayPal Client Secret"
                                    id="integrations.paypal.client_secret"
                                    name="integrations.paypal.client_secret"
                                    value={values?.integrations?.paypal?.client_secret}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <KeyIcon sx={{ transform: 'rotate(90deg)' }} />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!!errors.integrations?.paypal?.client_secret && !!touched.integrations?.paypal?.client_secret}
                                    helperText={
                                        touched.integrations?.paypal?.client_secret ? errors.integrations?.paypal?.client_secret : undefined
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ) : null}

                <Divider />

                <InputRow label="Sales Tax">
                    <Switch
                        value={values.sales_tax.enabled}
                        checked={values.sales_tax.enabled}
                        onChange={(_, v) => setFieldValue('sales_tax.enabled', v)}
                    />
                </InputRow>

                {values.sales_tax.enabled ? (
                    <Stack spacing={3} sx={{ width: '100%', maxWidth: '482px' }}>
                        <Box>
                            <TaxTreatmentSelect
                                label="Tax Treatment"
                                fullWidth
                                id="sales_tax.included_in_price"
                                name="sales_tax.included_in_price"
                                value={values.sales_tax.included_in_price}
                                onBlur={handleBlur}
                                onChange={(v) => setFieldValue('sales_tax.included_in_price', v)}
                                error={Boolean(errors.sales_tax?.included_in_price && touched.sales_tax?.included_in_price)}
                                helperText={touched.sales_tax?.included_in_price ? errors.sales_tax?.included_in_price : undefined}
                            />
                        </Box>
                        <Box>
                            <LabeledSwitch
                                fullWidth
                                value={Boolean(values.sales_tax.use_for_services)}
                                label="Services Sales Tax"
                                onChange={(_, v) => setFieldValue('sales_tax.use_for_services', v)}
                                error={errors.sales_tax?.use_for_services}
                            />
                        </Box>
                        <Box>
                            <LabeledSwitch
                                fullWidth
                                onChange={(_, v) => setFieldValue('sales_tax.use_for_products', v)}
                                value={Boolean(values.sales_tax.use_for_products)}
                                label="Products Sales Tax"
                                error={errors.sales_tax?.use_for_products}
                            />
                        </Box>
                        <Box>
                            <LabeledSwitch
                                fullWidth
                                onChange={(_, v) => setFieldValue('sales_tax.override_allowed', v)}
                                value={values.sales_tax.override_allowed}
                                label="Allow Override on Location Level"
                            />
                        </Box>
                    </Stack>
                ) : null}

                <Divider />

                <InputRow label="Enable Cash Ledger">
                    <Switch
                        onChange={(_, v) => setFieldValue('payments.use_cash_ledger', v)}
                        value={Boolean(values.payments.use_cash_ledger)}
                        checked={Boolean(values.payments.use_cash_ledger)}
                    />
                </InputRow>
            </Stack>
        </>
    );
};

export default PaymentsTab;
