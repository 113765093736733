import { Box, Tooltip } from '@mui/material';
import ContactSupportOutlined from '@mui/icons-material/ContactSupportOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShowSupportWidget } from '../../../store/slices/layoutSlice';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';

const SupportWidget = () => {
    const dispatch = useAppDispatch();
    const { showSupportWidget } = useAppSelector((state) => state.layout);

    const handleClick = () => {
        dispatch(setShowSupportWidget(!showSupportWidget));
    };

    return (
        <Tooltip title="Support" placement="top" arrow>
            <Box component="span">
                <CommonHeaderBtn onClick={handleClick}>
                    <ContactSupportOutlined />
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default SupportWidget;
